import React, { ChangeEvent } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';

interface CurrencyInputProps extends Omit<FormControlProps, 'onChange'> {
  value: string;
  onChange: (value: string) => void;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  value,
  onChange,
  ...props
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;

    // Remove todos os caracteres não numéricos, exceto vírgula
    inputValue = inputValue.replace(/[^\d,]/g, '');

    // Garante que há apenas uma vírgula
    const parts = inputValue.split(',');
    if (parts.length > 2) {
      inputValue = parts[0] + ',' + parts.slice(1).join('');
    }

    // Limita a duas casas decimais
    if (parts[1] && parts[1].length > 2) {
      inputValue = parts[0] + ',' + parts[1].slice(0, 2);
    }

    // Formata o número com separadores de milhar
    const [integerPart, decimalPart] = inputValue.split(',');
    let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Reconstrói o valor formatado
    let formattedValue =
      formattedInteger + (decimalPart ? ',' + decimalPart : '');

    // Adiciona o símbolo da moeda
    formattedValue = 'R$ ' + formattedValue;

    onChange(formattedValue);
  };

  return (
    <Form.Control
      type="text"
      value={value}
      onChange={handleChange}
      {...props}
    />
  );
};

// Função para remover a formatação e converter para número
export const convertToNumber = (formattedValue: string): number => {
  return Number(formattedValue.replace(/[^\d,]/g, '').replace(',', '.'));
};

export default CurrencyInput;
